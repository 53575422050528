import { Region } from '@entities/region';

export default {
  words: {
    remove: 'Eliminar',
    contact: 'Contacto',
  },
  texts: {
    noResults: 'Ningún resultado encontrado',
    search: 'Busque por nombre, CURP, teléfono o placa',
  },
  navbar: {
    support: 'Soporte',
    region: 'Región',
    zeroNotifications: 'No tienes notificaciones',
  },
  region: {
    changeRegionButton: 'Cambia región',
    changeRegionErrorTitle: 'Atención, no tienes acceso a esta región.!',
    changeRegionWarningTitle: 'Atención, estás cambiando de región!',
    regionFrom: 'Tu región:',
    regionTo: 'Región accediendo:',
    regionGroupEnum: Region.cityEnum,
  },
};
